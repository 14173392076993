<script setup lang="ts">
import { inject, type Ref } from 'vue'
import CalculationTable from '@/components/calculation/CalculationTable.vue'

const dialog = inject<Ref<boolean>>('calculationsViewShowDialog')

function close() {
  if (dialog) {
    dialog.value = false
  }
}
</script>

<template>
  <v-dialog :model-value="dialog" persistent>
    <v-card class="pa-3">
      <v-card-title> Calculation log</v-card-title>
      <v-card-text class="pl-4">
        <CalculationTable />
      </v-card-text>
      <v-card-actions class="pa-0">
        <v-spacer></v-spacer>
        <v-btn @click="close" variant="text" class="text-none" color="primary"> Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped></style>
