import AppBaseStore from './baseStore'
import { defineStore } from 'pinia'
import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import type {
  GiDataUploadCollectionType,
  GiPointType,
  UploadFileType
} from '@/stores/type/giPoint.type'
import API from '@/api/apiUrls'
import { useApi } from '@/api/useApi'
import { useGiPointStore } from '@/stores/giPoints'
import { GEF } from '@/constants/giDataUploadCollection'

const { api } = useApi()

type State = BaseStoreType<GiDataUploadCollectionType>

const baseUrl = API.GI_DATA_UPLOAD_COLLECTIONS.INDEX
const baseStore: BaseStoreType<GiDataUploadCollectionType> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<GiDataUploadCollectionType> = baseStore.actions
export const useGiDataUploadCollectionStore = defineStore({
  id: 'giDataUploadCollection',
  state: (): State => ({
    ...baseStore.state,
    uploadLoading: false,
    showUploadDialog: false,
    uploadFileType: GEF,
    filterFields: ['name']
  }),
  actions: {
    ...baseStoreActions,
    resetAll(): void {
      this.$reset()
    },
    toggleUploadLoading(value: boolean | null = null): void {
      this.uploadLoading = value ?? !this.uploadLoading
    },
    setUploadFileType(uploadFileType: UploadFileType): void {
      this.uploadFileType = uploadFileType
    },
    updateGiPoints(giDataUploadCollection: GiDataUploadCollectionType): void {
      const giPointStore = useGiPointStore()
      const giPoints = giDataUploadCollection.gipoint_set.features
      giPointStore.replaceAndPushItemsByCollectionId(giPoints, giDataUploadCollection.id)
    },
    async postOrUpdate(item = null) {
      try {
        const response = this.currentItem.id
          ? await this.update(item, this.currentItem.id)
          : await this.create(item)
        if (response) {
          this.updateGiPoints(response)
        }
      } catch (e) {
        console.error(e)
      }
    },
    async createOpenGround(formData: FormData): Promise<GiDataUploadCollectionType> {
      let responseData = null
      try {
        const createOpenGroundUrl = API.GI_DATA_UPLOAD_COLLECTIONS.CREATE_OPEN_GROUND
        responseData = await api.service(createOpenGroundUrl).create(formData)
        this.items.push(responseData)
        this.updateGiPoints(responseData)
      } catch (e) {
        console.error(e)
      }
      return responseData
    },
    async updateOpenGround(id: number, formData: FormData): Promise<GiDataUploadCollectionType> {
      let responseData = null
      if (id) {
        try {
          const updateOpenGroundUrl = API.GI_DATA_UPLOAD_COLLECTIONS.UPDATE_OPEN_GROUND(id)
          responseData = await api.service(updateOpenGroundUrl).post(formData)
          if (responseData) {
            const index = this.items.findIndex((item) => item.id === responseData.id)
            this.items[index] = responseData
            this.updateGiPoints(responseData)
          }
        } catch (e) {
          console.error(e)
        }
      }
      return responseData
    },
    toggleUploadDialog(item: GIPointCollection = null) {
      if (item) {
        this.currentItem = item
      }
      this.showUploadDialog = !this.showUploadDialog
    },
    toggleSelectedItem(id: number): void {
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
      } else {
        const item = this.items.find((item) => item.id === id)
        this.selectedItems.push(item)
      }
    },
    toggleChildSelectedItems(id: number): void {
      const giPointStore = useGiPointStore()
      const item = this.items.find((item) => item.id === id)
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        giPointStore.selectAllItemsByCollectionId(item.id)
      } else {
        giPointStore.clearSelectedItemsByCollectionId(item.id)
      }
    },
    addSelectedItem(id: number): void {
      const item = this.items.find((item) => item.id === id)
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index === -1) {
        this.selectedItems.push(item)
      }
    },
    removeSelectedItem(id: number): void {
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
      }
    },
    selectAllItems(): void {
      this.selectedItems = [...this.items]
      const giPointStore = useGiPointStore()
      this.selectedItems.forEach((item) => {
        giPointStore.selectAllItemsByCollectionId(item.id)
      })
    },
    clearSelectedItems(): void {
      this.selectedItems = []
      const giPointStore = useGiPointStore()
      giPointStore.clearSelectedItems()
    }
  },
  getters: {
    ...baseStore.getters,
    giDataUploadCollectionOnGiPoint:
      (state: State) =>
      (id: number): GiDataUploadCollectionType | undefined => {
        return state.items.find((item: GiDataUploadCollectionType) => {
          if (item.id === id) {
            return item
          }
        })
      },
    hasCalculationCPTRelatedToSelectedItems: (state: State) => (): boolean => {
      const giPointStore = useGiPointStore()
      const giPoints: GiPointType[] = []
      state.selectedItems.forEach((item) => {
        giPoints.push(...item.gipoint_set.features)
      })
      return giPointStore.hasCalculationCPTRelatedToSelectedItems(giPoints)
    }
  }
})
