<script setup lang="ts">
import { useCalculationStore } from '@/stores/calculation'
import { storeToRefs } from 'pinia'
import { computed } from 'vue'
import { filteredHeaders } from '@/components/calculation/calculationHeaders'
import CalculationTableRow from '@/components/calculation/CalculationTableRow.vue'

const store = useCalculationStore()

const { currentItem, selectedItems } = storeToRefs(store)

const filteredHeadersComponent = computed(() => {
  return currentItem.value ? filteredHeaders(currentItem.value) : []
})
</script>

<template>
  <v-data-table
    key="id"
    item-value="id"
    :items="selectedItems"
    :headers="filteredHeadersComponent"
    color="primary"
    :items-per-page="10"
    :fixed-header="true"
    height="500px"
  >
    <template v-slot:item="{ item }">
      <CalculationTableRow :item="item" />
    </template>
  </v-data-table>
</template>

<style scoped></style>
