const API = {
  TOKEN: {
    ACCESS: `api/token/`,
    REFRESH: `api/token/refresh/`
  },
  ROLES: {
    INDEX: `roles/`
  },
  GI_DATA: {
    GI_DATA_UPLOAD_COLLECTIONS: {
      INDEX: `gi_data/gi_data_upload_collections/`
    },
    GI_POINTS: {
      INDEX: `gi_point_altered/`
    }
  },
  GI_DATA_UPLOAD_COLLECTIONS: {
    INDEX: `gi_data/gi_data_upload_collections/`,
    CREATE_OPEN_GROUND: `gi_data/gi_data_upload_collections/create_open_ground/`,
    UPDATE_OPEN_GROUND: (id) => `gi_data/gi_data_upload_collections/${id}/update_open_ground/`
  },
  CALCULATION: {
    BASE: `calculation/`,
    PROCESS: 'calculation_process/'
  },
  CALCULATION_CPT: {
    BASE: `calculation_cpt/`,
    REPORT: `calculation_cpt/export_to_pdf`
  },
  CPT: {
    BASE: `cpt/`
  },
  CORE: {
    PROJECTS: 'projects/',
    CALCULATION_PARAMETERS: 'calculation_parameters/',
    PROJECT_PARAMETER: 'project_parameters/',
    PROJECT_USERS: {
      INDEX: `project_users/`
    },
    USERS: {
      INDEX: `users/`,
      CURRENT_USER: `users/current_user/`
    }
  }
}

export default API
