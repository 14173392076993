<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { computed, type PropType } from 'vue'

import Calculation from '@/components/calculation-drawer/Calculation.vue'
import CalculationCPTTable from '@/components/calculation-drawer/CalculationCPTTable.vue'

import { useCalculationStore } from '@/stores/calculation'
import type { CalculationType } from '@/stores/type/calculation.type'

const store = useCalculationStore()
const authorizationStore = useAuthorizationStore()
const calculationStore = useCalculationStore()

const { notReadyItems } = storeToRefs(calculationStore)

const props = defineProps({
  items: {
    type: Array as PropType<CalculationType[]>,
    required: true
  },
  searchQuery: {
    type: String,
    required: false,
    default: ''
  }
})

const { selectedItems } = storeToRefs(calculationStore)

const showDialog = ref(false)
provide('scenarioDialog', showDialog)

const canChangeCalculation = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['change_calculation']
  })
})

const search = (calculation: CalculationType) => {
  const isMatch = calculation.name?.toLowerCase().includes(props.searchQuery.toLowerCase())
  return isMatch ? '' : props.searchQuery
}

const isReady = (id: number) => {
  return !notReadyItems.value.includes(id)
}
</script>

<template>
  <template v-if="items.length">
    <v-list-group
      v-for="calculationItem in items"
      :key="calculationItem.id"
      :value="calculationItem.id"
      :v-ripple="true"
      class="sub-group-item w-100"
      subgroup
    >
      <template v-slot:activator="{ props }">
        <v-list-item
          :nav="true"
          :v-ripple="true"
          class="ma-0 py-0"
          v-bind="props"
          :class="{ 'selected-item': selectedItems?.includes(calculationItem) }"
          :disabled="!isReady(calculationItem.id)"
        >
          <Calculation :item="calculationItem"></Calculation>
          <template #append>
            <div class="float-right">
              <v-btn
                icon="mdi-pencil"
                density="compact"
                variant="text"
                v-if="isReady(calculationItem.id)"
                :v-if="canChangeCalculation"
                color="primary"
                @click.stop="store.toggleCreateUpdateDialog(calculationItem)"
              >
              </v-btn>
              <v-progress-circular
                v-else
                indeterminate
                color="primary"
                size="20"
              ></v-progress-circular>
            </div>
          </template>
        </v-list-item>
      </template>
      <CalculationCPTTable
        :item="calculationItem"
        :search-query="search(calculationItem)"
        show-select
        :is-selected-table="selectedItems.includes(calculationItem)"
      ></CalculationCPTTable>
      <v-divider thickness="3" />
    </v-list-group>
  </template>
  <template v-else>
    <div class="text-center" style="width: 100%">
      <small style="opacity: 0.6"> There is no data available.</small>
    </div>
  </template>
</template>

<style scoped>
.selected-item,
.list-item:hover {
  background-color: #f4fcff;
}
</style>
