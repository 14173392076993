<script setup lang="ts">
import { provide } from 'vue'
import { computed, ref, watch } from 'vue'
import type { GiDataUploadCollectionType } from '@/stores/type/giPoint.type'
import { debounce } from 'lodash'
import { useGiPointStore } from '@/stores/giPoints'
import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection'
import { isFilterMatch } from '@/utils/filter'
import GIDeleteButton from '@/components/gi-drawer/GIDeleteButton.vue'
import CollectionUploadDialog from '@/components/gi-drawer/CollectionUploadDialog.vue'
import GIDataCollectionList from '@/components/gi-drawer/GIDataCollectionList.vue'

const props = defineProps({
  showTreeOnly: { type: Boolean, required: false, default: false },
  giTypeFilter: { type: Boolean, required: false, default: false }
})

const projectStore = useProjectStore()
const giPointsStore = useGiPointStore()
const giDataUploadCollectionsStore = useGiDataUploadCollectionStore()
const authorizationStore = useAuthorizationStore()

const { currentItem: currentProject } = storeToRefs(projectStore)
const { deleteDialog } = storeToRefs(giPointsStore)
const {
  items: giDataCollections,
  selectedItems: selectedGiDataCollections,
  fetchLoading
} = storeToRefs(giDataUploadCollectionsStore)

const filterItems = [
  { title: 'Borehole', value: 'borehole' },
  { title: 'CPT', value: 'SonderingGEF' }
]

const searchQuery = ref('')
const selectedFilter = ref('')
const openedGroup = ref<number[]>([])
provide('openedGroup', openedGroup)

const debouncedSearchQuery = computed({
  get() {
    return searchQuery.value
  },
  set: debounce((query) => {
    searchQuery.value = query
  }, 300)
})
const selectAll = computed({
  get() {
    return selectedGiDataCollections.value?.length === giDataCollections.value.length
  },
  set: (value) => {
    if (value) {
      giDataUploadCollectionsStore.selectAllItems()
    } else {
      giDataUploadCollectionsStore.clearSelectedItems()
    }
  }
})

const filteredGIPointCollections = computed(() => {
  if ((!searchQuery.value || searchQuery.value === '') && !selectedFilter.value) {
    return giDataCollections.value
  }

  return giDataCollections.value.filter((collection: GiDataUploadCollectionType) => {
    return collection.gipoint_set.features.some((giPoint) => {
      const locationId = giPoint.properties?.location_id?.toLowerCase()
      const giType = giPoint.properties?.gi_type
      const searchMatch =
        searchQuery.value && searchQuery.value !== ''
          ? locationId.includes(searchQuery.value.toLowerCase())
          : true
      const filterMatch = selectedFilter.value ? isFilterMatch(giType, selectedFilter.value) : true
      return searchMatch && filterMatch
    })
  })
})

const clearFilters = () => {
  searchQuery.value = ''
  selectedFilter.value = ''
}

watch(deleteDialog, () => {
  if (!deleteDialog.value) {
    giPointsStore.setDeleteItem({})
  }
})

const canAdd = computed(() => {
  return authorizationStore.hasServicePermission({
    authSource: 'ProjectUser',
    allowed: ['add_gidatauploadcollection']
  })
})
</script>

<template>
  <v-container class="pa-0">
    <div
      class="d-flex justify-center align-center ga-2 pt-2 pb-2 px-2 bg-white"
      style="top: 0; position: sticky; z-index: 1000"
    >
      <v-text-field
        v-model="debouncedSearchQuery"
        color="primary"
        append-inner-icon="mdi-magnify"
        density="compact"
        label="Search..."
        hide-details
        clearable
      ></v-text-field>
      <v-select
        v-if="giTypeFilter"
        :items="filterItems"
        v-model="selectedFilter"
        color="primary"
        density="compact"
        hide-details
        clearable
        style="max-width: 150px"
        label="GI Type"
      ></v-select>
      <v-btn
        prepend-icon="mdi-close-circle-outline"
        class="text-none"
        variant="tonal"
        @click.stop="clearFilters"
      >
        Clear filters
      </v-btn>
      <template v-if="!showTreeOnly">
        <v-tooltip text="Upload files" v-if="canAdd">
          <template v-slot:activator="{ props }">
            <v-btn
              v-bind="props"
              @click="giDataUploadCollectionsStore.toggleUploadDialog"
              class="text-none"
              prepend-icon="mdi-plus"
              variant="tonal"
            >
              Upload
            </v-btn>
          </template>
        </v-tooltip>
        <GIDeleteButton />
      </template>
    </div>

    <v-card-subtitle v-if="!showTreeOnly && filteredGIPointCollections.length">
      To show a GI Point on map, click on the CPT row in the calculation table.
    </v-card-subtitle>
    <v-list density="compact" class="overflow-y-auto px-0" v-model:opened="openedGroup">
      <template v-if="giDataCollections.length || filteredGIPointCollections.length">
        <v-list-item :class="{ 'selected-item': selectAll }" class="py-0" v-if="!searchQuery">
          <v-checkbox
            v-model="selectAll"
            density="compact"
            :disabled="!giDataCollections.length"
            color="primary"
            class="px-1 select-all-checkbox"
            hide-details
            label="Select all"
          ></v-checkbox>
        </v-list-item>
        <GIDataCollectionList
          :items="filteredGIPointCollections"
          :search-query="searchQuery"
          :selected-filter="selectedFilter"
          :show-tree-only="showTreeOnly"
        />
      </template>
      <template v-if="!giDataCollections.length">
        <v-list-item v-if="!fetchLoading">
          <v-list-item-title>
            There is no Ground Investigation Data available. Upload ground investigation data to
            continue.
          </v-list-item-title>
        </v-list-item>
        <v-list-item v-else><small> Loading... </small></v-list-item>
      </template>
    </v-list>
    <CollectionUploadDialog v-if="currentProject?.id" :linked-model-id="currentProject.id" />
  </v-container>
</template>

<style scoped>
:deep(.v-list-item__prepend) {
  width: 20px;
  margin-left: 4px;
  margin-right: 4px;
}

.selected-item {
  background-color: #f4fcff;
}

.list-item:hover {
  background-color: #f4fcff;
}

.select-all-checkbox :deep(.v-label) {
  font-weight: 600;
  color: black;
}
</style>
