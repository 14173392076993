<script setup lang="ts">
import { computed, defineProps, ref, watch } from 'vue'
import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection.ts'
import { storeToRefs } from 'pinia'
import {
  CSV,
  DATA_FILE_NAME,
  DESCRIPTION_FILE_NAME,
  DETAILS_FILE_NAME,
  GENERAL_FILE_NAME
} from '@/constants/giDataUploadCollection'

const props = defineProps({
  closeDialog: { type: Function, required: true },
  linkedModelId: { type: Number, required: true },
  showCollectionName: { type: Boolean, required: false, default: true },
  giSetName: { type: String, required: false, default: '' }
})

const REQUIRED_FILES = [DESCRIPTION_FILE_NAME, DETAILS_FILE_NAME, DATA_FILE_NAME, GENERAL_FILE_NAME]
const inputFile = ref<File | File[]>([])
const files = computed(() => {
  return Array.isArray(inputFile.value) ? inputFile.value : inputFile.value ? [inputFile.value] : []
})

const store = useGiDataUploadCollectionStore()

const { currentItem, uploadFileType } = storeToRefs(store)

watch(uploadFileType, () => {
  inputFile.value = []
})

const isEditing = computed(() => {
  return currentItem.value?.id || false
})

const isCorrectFiles = computed(() => {
  if (uploadFileType.value !== CSV) {
    return files.value.length === 1
  }
  return (
    REQUIRED_FILES.length === files.value.length &&
    REQUIRED_FILES.every((file) => files.value.some((f) => f.name === file))
  )
})

const uploadFile = async () => {
  store.toggleUploadLoading(true)
  const formData = new FormData()
  files.value.forEach((file) => {
    formData.append('source_files', file)
  })

  formData.append('project', props.linkedModelId?.toString())
  formData.append('name', props.giSetName)
  try {
    let response
    if (isEditing.value) {
      const id = currentItem.value?.id as number
      response = await store.updateOpenGround(id, formData)
    } else {
      response = await store.createOpenGround(formData)
    }
    emit('afterUploadHandler', response)
    store.toggleUploadLoading(false)
    inputFile.value = []
    props.closeDialog()
  } catch (error) {
    console.log(error)
    store.toggleUploadLoading(false)
  }
}

const label = computed(() => {
  return 'Upload ' + uploadFileType.value + ' file' + (uploadFileType.value === CSV ? 's' : '')
})

const multiple = computed(() => {
  return uploadFileType.value === CSV
})

const emit = defineEmits(['afterUploadHandler'])

defineExpose({
  isCorrectFiles,
  uploadFile
})
</script>

<template>
  <div class="overflow-y-auto">
    <v-file-input
      color="primary"
      v-model="inputFile"
      :multiple="multiple"
      prepend-icon=""
      append-icon="mdi-folder-zip"
      :label="label"
      :accept="uploadFileType"
    >
      <template #selection="{ fileNames }">
        <div v-for="fileName in fileNames" :key="fileName" class="chip pt-1" style="width: 100%">
          {{ fileName }}
        </div>
      </template>
    </v-file-input>
    <v-alert
      v-if="uploadFileType === CSV && files.length > 0"
      type="warning"
      variant="tonal"
      class="ma-1"
      density="compact"
      v-show="!isCorrectFiles"
    >
      Please provide exactly the following files:
      <div class="ml-2">
        <p v-for="file in REQUIRED_FILES" :key="file">- {{ file }}</p>
      </div>
    </v-alert>
  </div>
</template>
<style scoped>
.chip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
