<script lang="ts" setup>
import { computed, type PropType } from 'vue'
import type { PlotData } from 'plotly.js-basic-dist'

import PlotlyChart from '@/components/result-drawer/PlotlyChart.vue'

import type { chartComponentType } from '@/types/chartComponent.type'

const props = defineProps({
  data: {
    type: Object as PropType<chartComponentType[]>,
    required: true
  },
  hasExternalLegend: {
    type: Boolean,
    required: false,
    default: false
  }
})

function getMinXAxis(plotData: PlotData[]): number {
  if (!plotData.length) {
    return 0
  }
  let minWidth = 0
  for (const data of plotData) {
    const xValues = data.x as number[]
    if (xValues.length > 0) {
      minWidth = Math.min(minWidth, ...xValues)
    }
  }
  minWidth = minWidth * 1.2
  return minWidth < 0 ? minWidth : 0
}

function getMaxXAxis(plotData: PlotData[]): number {
  if (!plotData.length) {
    return 0
  }
  let maxWidth = 0
  for (const data of plotData) {
    const xValues = data.x as number[]
    if (xValues.length > 0) {
      maxWidth = Math.max(maxWidth, ...xValues)
    }
  }
  maxWidth = maxWidth * 1.2
  return maxWidth < 0 ? 0 : maxWidth
}

function getMaxLevel(plotData: PlotData[]): number {
  const yValues = plotData[0]?.y as number[]
  return yValues.length > 0 ? yValues[0] : 0
}

function getMinLevel(plotData: PlotData[]): number {
  const yValues = plotData[0]?.y as number[]
  return yValues.length > 0 ? yValues[yValues.length - 1] : 0
}

function getLineNumbers(plotData: PlotData[]): number {
  return plotData.filter((data) => data.line.color != 'transparent').length
}

const chartHeightClass = computed(() => {
  return props.hasExternalLegend ? 'fill-result' : 'fill-height'
})
</script>

<template>
  <div class="row fill-height px-3">
    <template v-if="data.length">
      <div class="fill-height">
        <template v-for="(chart, index) in data">
          <div v-if="chart.data.length > 0" v-bind:key="index" class="col fill-height">
            <v-lazy :class="[chartHeightClass]">
              <PlotlyChart
                :data="chart.data"
                :max-x-axis="getMaxXAxis(chart.data)"
                :min-x-axis="getMinXAxis(chart.data)"
                :max-y-axis="getMaxLevel(chart.data)"
                :min-y-axis="getMinLevel(chart.data)"
                :x-axis-title="chart.xAxisTitle"
                :line-numbers="getLineNumbers(chart.data)"
                :title="chart.title"
              />
            </v-lazy>
          </div>
        </template>
      </div>
    </template>
    <template v-else>
      <div class="col fill-height" style="width: 100%">
        <v-card class="fill-height" style="width: 100%" flat>
          <v-card-text class="text-center"> No data available</v-card-text>
        </v-card>
      </div>
    </template>
  </div>
</template>

<style scoped>
.fill-result {
  height: calc(100vh - 240px);
}

.row {
  container: table / inline-size;
}

.row::after {
  display: table;
}

.col {
  float: left;
  width: 99%;
}

@container table (min-width: 330px) {
  .col {
    width: 49.5%;
  }
}

@container table (min-width: 600px) {
  .col {
    width: 33.33%;
  }
}

@container table (min-width: 750px) {
  .col {
    width: 25%;
  }
}

@container table (min-width: 920px) {
  .col {
    width: 20%;
  }
}

@container table (min-width: 1080px) {
  .col {
    width: 16.66%;
  }
}
</style>
