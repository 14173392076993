<script lang="ts" setup>
import { getDisplayNameFromOptions } from '@/components/calculation/getDisplayNameFromOptions'
import { useCalculationStore } from '@/stores/calculation'
import type { PropType } from 'vue'
import type { CalculationType } from '@/stores/type/calculation.type'

const props = defineProps({
  item: { type: Object as PropType<CalculationType>, required: true }
})

const store = useCalculationStore()
</script>

<template>
  <tr>
    <td class="table-width">
      {{ item.name }}
    </td>
    <td class="table-width">
      {{ item.ground_water_table_insitu }}
    </td>
    <td class="table-width">
      {{ item.ground_water_table_design }}
    </td>
    <td class="table-width">
      {{ item.load }}
    </td>
    <td class="table-width">
      {{ item.max_acceleration }}
    </td>
    <td class="table-width">
      {{ item.return_period }}
    </td>
    <td class="table-width">
      {{ item.Ic_limit }}
    </td>
    <td class="table-width">
      {{ getDisplayNameFromOptions(item.liquefaction_method, store.fields, 'liquefaction_method') }}
    </td>
    <td class="table-width">
      {{
        getDisplayNameFromOptions(
          item.liquefaction_settlement,
          store.fields,
          'liquefaction_settlement'
        )
      }}
    </td>
    <td class="table-width">
      {{ getDisplayNameFromOptions(item.soil_classification, store.fields, 'soil_classification') }}
    </td>
    <td class="table-width">
      {{ getDisplayNameFromOptions(item.relative_density, store.fields, 'relative_density') }}
    </td>
  </tr>
</template>
