<script setup lang="ts">
import type { ReportField } from '@/stores/type/calculationCPT.type'

const store = useCalculationCPTStore()

const { selectedItems, exportLoading, exportDialog, reportFields } = storeToRefs(store)

const REPORT_PARAMS: (ReportField | '')[][] = [
  ['CSR', 'CRR', 'fos_liq', 's', ''],
  ['cone_resistance', 'rud_s', 'qc_s', 'rud_ps', 'qc_ps'],
  ['LPI', 'LDI', 'LSN', 'PL', '']
]

const paramTitles: { [k in ReportField]: string } = {
  CSR: 'Cycle Stress Ratio',
  CRR: 'Cycle Resistance Ratio',
  fos_liq: 'Factor of Safety',
  s: 'Settlement',
  cone_resistance: 'Cone Resistance',
  rud_s: 'Excess Pore Pressure Seismic',
  qc_s: 'qc reduction seismic',
  rud_ps: 'Excess Pore Pressure Post Seismic',
  qc_ps: 'qc reduction post seismic',
  LPI: 'LPI',
  LDI: 'LDI',
  LSN: 'LSN',
  PL: 'PL',
  LPI_summary: 'LPI Summary',
  LSN_summary: 'LSN Summary'
}

async function exportReport() {
  await store.exportReport()
  store.toggleExportDialog(false)
}
</script>
<template>
  <v-dialog
    :model-value="exportDialog"
    @click:outside="store.toggleExportDialog()"
    max-width="1500"
  >
    <v-card class="pa-3">
      <v-card-title>Export PDF report</v-card-title>
      <v-card-text>
        For each CPT:
        <v-row no-gutters v-for="row in REPORT_PARAMS" :key="row[0]">
          <v-col v-for="param in row" :key="param">
            <v-checkbox
              v-if="param"
              v-model="reportFields"
              :value="param"
              density="compact"
              hide-details
              :label="paramTitles[param]"
              color="primary"
            ></v-checkbox>
          </v-col>
        </v-row>
        <template v-if="selectedItems.length > 1">
          Summary of CPTs:
          <v-row no-gutters>
            <v-col>
              <v-checkbox
                v-model="reportFields"
                value="LPI_summary"
                density="compact"
                hide-details
                :label="paramTitles['LPI_summary']"
                color="primary"
              ></v-checkbox>
            </v-col>
            <v-col>
              <v-checkbox
                v-model="reportFields"
                value="LSN_summary"
                density="compact"
                hide-details
                :label="paramTitles['LSN_summary']"
                color="primary"
              ></v-checkbox>
            </v-col>
            <v-col />
            <v-col />
            <v-col />
          </v-row>
        </template>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="exportLoading"
          color="primary text-none"
          @click="store.toggleExportDialog()"
        >
          Cancel
        </v-btn>
        <v-btn :loading="exportLoading" color="primary text-none" @click="exportReport()">
          Export
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
