<script setup lang="ts">
import CreateOrUpdateDialog from '@/components/dialog/CreateOrUpdateDialog.vue'
import DeleteDialog from '@/components/dialog/DeleteDialog.vue'
import { onMounted, onUnmounted, watch } from 'vue'
import { storeToRefs } from 'pinia/dist/pinia'
import { useProjectStore } from '@/stores/project'
import { useAuthorizationStore } from '@/stores/authorization'
import { ProjectIndex, RHDHVWorkOrderField } from '@rhdhv/vue-component-library'
import router from '@/router'
import type { ProjectType } from '@/stores/type/project.type'

const authorizationStore = useAuthorizationStore()
const store = useProjectStore()
const projectUserStore = useProjectUserStore()
const authStore = useDjangoAuthStore()

const { user } = storeToRefs(authStore)
const {
  items,
  currentItem,
  createUpdateDialog,
  deleteDialog,
  deleteItem,
  writeLoading: loading
} = storeToRefs(store)

watch(createUpdateDialog, async () => {
  if (!createUpdateDialog.value) {
    store.setCurrentItem({})
  }
  store.servicePermissionItems()
})

watch(deleteDialog, () => {
  if (!deleteDialog.value) {
    store.setDeleteItem({})
  }
})

async function postOrUpdate() {
  const response = await store.postOrUpdate()
  if (response) {
    store.toggleCreateUpdateDialog()
  }
}

function addProject() {
  store.setCurrentItem({})
  store.toggleCreateUpdateDialog()
}

function deleteProject(item: ProjectType) {
  store.toggleDeleteDialog(item)
}

function editItem(item: ProjectType) {
  store.toggleCreateUpdateDialog(JSON.parse(JSON.stringify(item)))
}

const navigateToProject = (projectID: number) => {
  router.push({ name: 'project-detail', params: { id: projectID } })
}

const codeField = computed(() => {
  return store.fieldsList.find((field: any) => field.parameter === 'work_order')
})

onMounted(async () => {
  await authorizationStore.fetchItems()
  if (user.value) {
    authorizationStore.setCurrentRole([user.value.role])
  }
  if (!items || !items.value.length) {
    await store.fetchOptions()
    await store.fetchItems()
  }
  await store.servicePermissionItems()
})

onUnmounted(() => {
  projectUserStore.resetState()
  authorizationStore.resetState()
})
</script>

<template>
  <div class="fill-height">
    <ProjectIndex
      title="Liquefaction"
      :items="items"
      :loading="loading"
      :get-change-permission="store.getChangePermission"
      :get-delete-permission="store.getDeletePermission"
      @navigate-to-project="navigateToProject"
      @add-item="addProject"
      @edit-item="editItem"
      @delete-item="deleteProject"
    />
    <CreateOrUpdateDialog :store="store" name="project" @save="postOrUpdate()">
      <RHDHVWorkOrderField
        v-if="codeField"
        :field="codeField"
        validate-code
        :model-value="currentItem"
      ></RHDHVWorkOrderField>
    </CreateOrUpdateDialog>
    <DeleteDialog :store="store" :name="deleteItem?.name" @delete="store.delete()"></DeleteDialog>
  </div>
</template>

<style scoped>
:deep(.v-table__wrapper table) {
  border: 1px solid lightgrey;
}
</style>
