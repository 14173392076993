<script setup lang="ts">
import GefFileUpload from '@/components/gi-drawer/GefFileUpload.vue'
import { storeToRefs } from 'pinia'
import { computed, inject, onMounted, type Ref, ref, watch } from 'vue'
import { CSV, GEF, ZIP } from '@/constants/giDataUploadCollection'
import OpenGroundFileUpload from '@/components/gi-drawer/OpenGroundFileUpload.vue'
import type { GiDataUploadCollectionType, GiPointType } from '@/stores/type/giPoint.type'
import { useGiPointStore } from '@/stores/giPoints'

const store = useGiDataUploadCollectionStore()
const giPointsStore = useGiPointStore()

const { currentItem, showUploadDialog, uploadFileType, uploadLoading } = storeToRefs(store)
const { items: giPoints } = storeToRefs(giPointsStore)

const props = defineProps({
  linkedModelId: { type: Number, required: true },
  title: { type: String, required: false, default: '' },
  subTitle: {
    type: String,
    required: false,
    default: 'Ground investigation data upload collection'
  },
  showCollectionName: { type: Boolean, required: false, default: true }
})

const openedGroup = inject<Ref<number[]>>('openedGroup')

const gefFileUpload = ref<InstanceType<typeof GefFileUpload> | null>(null)
const openGroundFileUpload = ref<InstanceType<typeof OpenGroundFileUpload> | null>(null)
const giSetName = ref('')

const createOrUpdateTitle = computed(() => {
  let title = ''
  if (isEditing.value) {
    title += 'Edit'
  } else {
    title += 'Upload'
  }
  return title
})

watch(showUploadDialog, (value) => {
  if (value) {
    giSetName.value = currentItem.value?.name || ''
  }
})

const isEditing = computed(() => {
  return currentItem.value?.id || false
})

const closeDialog = () => {
  store.toggleUploadDialog()
  giSetName.value = ''
  store.setCurrentItem({})
}

const afterUploadHandler = (responseData: GiDataUploadCollectionType | null) => {
  const isNewItem = !currentItem.value?.id
  if (responseData?.gipoint_set) {
    if (!isNewItem) {
      const newGiPoints = responseData.gipoint_set.features.filter((feature: GiPointType) => {
        return !giPoints.value.some((point: GiPointType) => point.id === feature.id)
      })
      giPointsStore.addItems(newGiPoints)
    }
  }
  if (responseData?.id) {
    openedGroup?.value.push(responseData.id)
  }
}

const uploadMethod = computed({
  get: () => {
    if (uploadFileType.value === GEF) {
      return 'gef'
    } else {
      return 'openground'
    }
  },
  set: (value) => {
    if (value === 'gef') {
      store.setUploadFileType(GEF)
    } else {
      store.setUploadFileType(CSV)
    }
  }
})

const uploadMethodItems = [
  { text: 'GEF files', value: 'gef' },
  { text: 'Open ground', value: 'openground' }
]

const uploadType = computed({
  get: () => uploadFileType.value,
  set: (value) => {
    store.setUploadFileType(value)
  }
})

const UploadFileTypeItems = [
  { text: '.csv', value: CSV },
  { text: '.zip', value: ZIP }
]

const uploadFile = async () => {
  if (uploadFileType.value === GEF) {
    await gefFileUpload.value?.uploadFile()
  } else {
    await openGroundFileUpload.value?.uploadFile()
  }
}

const disableSaveButton = computed(() => {
  if (giSetName.value === '') {
    return true
  }
  if (isEditing.value) {
    return false
  }
  if (uploadFileType.value === GEF) {
    return !gefFileUpload.value?.isCorrectFiles
  } else {
    return !openGroundFileUpload.value?.isCorrectFiles
  }
})

onMounted(() => {
  giSetName.value = currentItem.value?.name || ''
})
</script>

<template>
  <v-dialog :model-value="showUploadDialog" max-width="850" @click:outside="closeDialog">
    <v-card class="pa-3">
      <v-card-title class="pb-0"> {{ createOrUpdateTitle }}</v-card-title>
      <v-card-subtitle> {{ props.subTitle }}</v-card-subtitle>
      <v-row class="px-4 mt-3">
        <v-col cols="6">
          <v-select
            v-model="uploadMethod"
            :items="uploadMethodItems"
            item-value="value"
            item-title="text"
            label="Upload method"
            color="primary"
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-select
            v-if="uploadFileType !== GEF"
            v-model="uploadType"
            :items="UploadFileTypeItems"
            label="Upload file type"
            item-value="value"
            item-title="text"
            color="primary"
            outlined
            hide-details
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-text-field
            v-if="showCollectionName"
            color="primary"
            label="Collection Name"
            v-model="giSetName"
            type="text"
            class="mb-2"
            :clearable="true"
          ></v-text-field>

          <GefFileUpload
            v-if="uploadFileType === GEF"
            ref="gefFileUpload"
            :gi-set-name="giSetName"
            :show-collection-name="props.showCollectionName"
            :close-dialog="closeDialog"
            :linked-model-id="linkedModelId"
            @after-upload-handler="afterUploadHandler"
          />
          <OpenGroundFileUpload
            v-else
            ref="openGroundFileUpload"
            :gi-set-name="giSetName"
            :show-collection-name="props.showCollectionName"
            :close-dialog="closeDialog"
            :linked-model-id="linkedModelId"
            @after-upload-handler="afterUploadHandler"
          >
          </OpenGroundFileUpload>
        </v-col>
      </v-row>

      <v-card-actions class="pa-0 justify-end">
        <v-btn color="primary" :disabled="uploadLoading" class="text-none" @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
          color="primary"
          :disabled="disableSaveButton"
          :loading="uploadLoading"
          @click="uploadFile"
          class="text-none"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
