<script setup lang="ts">
import { defineProps, ref, watch } from 'vue'
import { useGiDataUploadCollectionStore } from '@/stores/giDataUploadCollection.ts'
import { storeToRefs } from 'pinia'

const props = defineProps({
  closeDialog: { type: Function, required: true },
  linkedModelId: { type: Number, required: true },
  showCollectionName: { type: Boolean, required: false, default: true },
  giSetName: { type: String, required: false, default: '' }
})

const files = ref([])

const isCorrectFiles = computed(() => {
  return files.value.length > 0
})

const store = useGiDataUploadCollectionStore()

const { uploadFileType } = storeToRefs(store)

watch(uploadFileType, () => {
  files.value = []
})

const uploadFile = async () => {
  store.toggleUploadLoading(true)
  const formData = new FormData()
  files.value.forEach((file) => {
    formData.append('source_files', file)
  })

  formData.append('project', props.linkedModelId?.toString())
  formData.append('name', props.giSetName)
  try {
    const response = await store.postOrUpdate(formData)
    emit('afterUploadHandler', response)
    store.toggleUploadLoading(false)
    files.value = []
    props.closeDialog()
  } catch (error) {
    console.log(error)
    store.toggleUploadLoading(false)
  }
}

const emit = defineEmits(['afterUploadHandler'])

defineExpose({
  isCorrectFiles,
  uploadFile
})
</script>

<template>
  <div class="overflow-y-auto">
    <v-file-input
      color="primary"
      :multiple="true"
      v-model="files"
      prepend-icon=""
      append-icon="mdi-folder-zip"
      label="Upload .gef files"
      :accept="uploadFileType"
    >
      <template #selection="{ fileNames }">
        <div v-for="fileName in fileNames" :key="fileName" class="chip pt-1" style="width: 100%">
          {{ fileName }}
        </div>
      </template>
    </v-file-input>
  </div>
</template>
<style scoped>
.chip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
