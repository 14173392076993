import AppBaseStore from './baseStore'
import { defineStore } from 'pinia'

import type { BaseStoreActions, BaseStoreType } from '@/types/storeTypes'
import type { GiPointType } from '@/stores/type/giPoint.type'
import API from '@/api/apiUrls'
import { useApi } from '@/api/useApi'
import type { CalculationCPTType } from '@/stores/type/calculationCPT.type'

const { api } = useApi()

type State = BaseStoreType<GiPointType>

const baseUrl = API.GI_DATA.GI_POINTS.INDEX
const baseStore: BaseStoreType<GiPointType> = new AppBaseStore(baseUrl, api)
const baseStoreActions: BaseStoreActions<GiPointType> = baseStore.actions
export const useGiPointStore = defineStore({
  id: 'giPoints',
  state: (): State => ({
    ...baseStore.state,
    loading: false,
    filterFields: ['name'],
    selectedItems: []
  }),
  actions: {
    ...baseStoreActions,
    resetAll(): void {
      this.$reset()
    },
    async fetchItems(
      filter: { [key: string]: string | number } = {}
    ): Promise<GiPointType[] | boolean> {
      this.fetchLoading = true
      try {
        const response = await api.service(baseUrl).find({
          query: filter
        })
        this.items = response.data.features
      } catch (e) {
        this.setSnackbarMessage('Fetch items failed.', 'error')
        console.error(e)
        this.fetchLoading = false
        return false
      }
      this.fetchLoading = false
      return this.items
    },
    removeItem(payload) {
      const index = this.items.findIndex((item) => payload.id === item.id)
      this.items.splice(index, 1)
      if (this.currentItem && payload.id === this.currentItem.id) {
        this.currentItem = {}
      }
    },
    // Add gidatauploadcollection field to handle authorization
    pushSelectedItem(item: GiPointType): void {
      item['gidatauploadcollection'] = item.properties.gi_data_upload_collection
      this.selectedItems.push(item)
    },
    replaceAndPushItemsByCollectionId(items: GiPointType[], id: number): void {
      this.items = this.items.filter((item) => item.properties.gi_data_upload_collection !== id)
      this.items.push(...items)
    },
    toggleSelectedItem(id: number): void {
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)

      const giDataUploadCollectionStore = useGiDataUploadCollectionStore()
      const giPoint = this.items.find((item) => item.id === id)

      if (index !== -1) {
        this.selectedItems.splice(index, 1)
        giDataUploadCollectionStore.removeSelectedItem(
          giPoint?.properties.gi_data_upload_collection
        )
      } else {
        this.pushSelectedItem(giPoint)
        const selectedItemLength = this.getSelectedItemsByCollectionId(
          giPoint?.properties.gi_data_upload_collection
        ).length
        const itemLength = this.getItemsByCollectionId(
          giPoint?.properties.gi_data_upload_collection
        ).length
        if (selectedItemLength === itemLength) {
          giDataUploadCollectionStore.addSelectedItem(giPoint?.properties.gi_data_upload_collection)
        }
      }
    },
    addSelectedItem(id: number): void {
      const item = this.items.find((item) => item.id === id)
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index === -1) {
        this.pushSelectedItem(item)
      }
    },
    removeSelectedItem(id: number): void {
      const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === id)
      if (index !== -1) {
        this.selectedItems.splice(index, 1)
      }
    },
    selectAllItemsByCollectionId(id: number): void {
      const selectedItems = this.items.filter(
        (item) => item.properties.gi_data_upload_collection === id
      )
      selectedItems.forEach((item) => {
        const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === item.id)
        if (index === -1) {
          this.pushSelectedItem(item)
        }
      })
    },
    clearSelectedItemsByCollectionId(id: number): void {
      const selectedItems = this.items.filter(
        (item) => item.properties.gi_data_upload_collection === id
      )
      selectedItems.forEach((item) => {
        const index = this.selectedItems.findIndex((selectedItem) => selectedItem.id === item.id)
        if (index !== -1) {
          this.selectedItems.splice(index, 1)
        }
      })
    },
    selectAllItems(): void {
      this.selectedItems = [...this.items]
    },
    clearSelectedItems(): void {
      this.selectedItems = []
    }
  },
  getters: {
    ...baseStore.getters,
    giPointsByType:
      () =>
      (giPoints: GiPoint[], type: 'boreholes' | 'cpts'): GiPointType[] => {
        return giPoints.filter((item: GiPoint) => item.properties[type].length > 0)
      },
    hasCalculationCPTRelatedToSelectedItems:
      (state: State) =>
      (giPointItems: GiPointType[] = null): boolean => {
        const calculationCPT = useCalculationCPTStore()
        const calculationCPTItems = calculationCPT.getStrippedItems()
        const items = giPointItems ?? state.selectedItems
        return items.some(
          (selectedItem) =>
            calculationCPTItems.findIndex(
              (item: CalculationCPTType) => item.cpt === selectedItem.properties.cpt.id
            ) !== -1
        )
      },
    getGIPointByCPTId:
      (state) =>
      (cptId: number): GiPointType | undefined => {
        return state.items.find((item) => item.properties.cpt.id === cptId)
      },
    getItemById:
      (state) =>
      (id: number): GiPointType | undefined => {
        return state.items.find((item) => item.id === id)
      },
    getSelectedItemsByCollectionId:
      (state) =>
      (id: number): GiPointType[] => {
        return state.selectedItems.filter(
          (item) => item.properties.gi_data_upload_collection === id
        )
      },
    getItemsByCollectionId:
      (state) =>
      (id: number): GiPointType[] => {
        return state.items.filter((item) => item.properties.gi_data_upload_collection === id)
      }
  }
})
