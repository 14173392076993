export const NCEER = 'NCEER2001'
export const BOULANGER_IDRISS_DET = 'BoulangerIdriss2014Deterministic'
export const BOULANGER_IDRISS_PROB = 'BoulangerIdriss2014Probabilistic'
export const MOSS_PROB = 'MossEtAl2006Probabilistic'

export type LiquefactionMethodType =
  | typeof NCEER
  | typeof BOULANGER_IDRISS_DET
  | typeof BOULANGER_IDRISS_PROB
  | typeof MOSS_PROB

export type CalculationCPTDataPointType = {
  id: number
  liquefaction_method: LiquefactionMethodType
  depth: number
  measured_cone_resistance: number
  fs: number
  level: number
  thickness_increment: number
  assessment_soil_pressure: number
  assessment_water_pressure_ass: number
  qc_homogeneous: number
  Rf: number
  Rf_qt: number
  water_pressure: number
  soil_pressure: number
  effective_soil_pressure: number
  qt_kPa: number
  qn: number
  qtn: number
  qt_homogeneous: number
  qc1: number
  qc_1N: number
  d_qc_1N: number
  qc_1Ncs: number
  normalized_friction_ratio: number
  SBT_Robertson2010: number
  bulk_density: number
  Kh: number
  Q_050: number
  Q_070: number
  Q_1: number
  Ic_050: number
  Ic_070: number
  Ic_1: number
  Ic_def: number
  fos_liq: number
  MSF: number
  maximum_MSF: number
  CRR75: number
  CRR: number
  CSR: number
  Ksigma: number
  Csigma: number
  rd: number
  exp_m: number
  Cn: number
  FC: number
  LPI: number
  F_LPI: number
  W_z: number
  dPL: number
  rud_s: number
  rud_ps: number
  Dr: number
  Evc_max: number
  d_s: number
  s: number
  Ic_Robertson2010: number
  qc_s: number
  qc_ps: number
  LDI: number
  LSN: number
  PL: number
  calculation_cpt: number
}
