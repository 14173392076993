import type { ChoiceType } from '@/stores/type/store.type'

export function getDisplayNameFromOptions(value: string | string[], fields: any, key: string) {
  const result: string[] = []
  const field = fields[key].child ? fields[key].child : fields[key]
  field.choices.forEach((choice: ChoiceType<string>) => {
    if (value.includes(choice.value)) {
      result.push(choice.display_name)
    }
  })
  return result.join(', ')
}
